import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import sat from '../img/404.svg'
const NotFoundPage = () => (
  <Layout>
  <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>404</b></span>
  </nav>
    <div>


    <div
      style={{
        backgroundImage: `url(${
          sat
        })`,
        backgroundPosition: `bottom center`,
        height:'600px',
        backgroundRepeat: 'no-repeat',

      }}
    >

    <div id="nf"  style={{
    height:'400px',
    width: '40%',
    marginLeft: '10%',
    margin: '20px',
    padding: '40px',
    borderRadius: '10px',
    }}>
      <h1
      style={{

        color: '#36b3d2',
        height:'50px',
        fontSize: '35pt',
        marginBottom: '50px'

      }}
      ><b>404</b></h1>
      <h2 style={{height:'100px'}}>Wygląda na to, że to czego szukasz niestety <b style={{color:'#36b3d2'}}>nie istnieje.</b> </h2>

      <Link to="/" className="btn"> Wróć na stronę. </Link>
      </div>
    </div>
    </div>
  </Layout>
)

export default NotFoundPage
